import {NOTIFICATION_EVENT_TYPE, NotificationOutDto} from "../service/notification.service";

export class NotificationUtil {

    static getDescription(notificationOutDto: NotificationOutDto): string {
        switch (notificationOutDto?.notificationEventType) {
            case NOTIFICATION_EVENT_TYPE.APPLICANT_STATUS_CHANGED:
                return `Update : Your ${notificationOutDto?.jobPostName} application is now '${notificationOutDto?.currentApplicantStatus || notificationOutDto?.applicantStatus}' ${notificationOutDto?.applicantName} !`;
            case NOTIFICATION_EVENT_TYPE.APPLICANT_HIRING_ROUND_CHANGED:
                return `Your application for ${notificationOutDto?.jobPostName} is under process for '${notificationOutDto?.currentHiringRoundName}' round.
                We'll keep you posted on any updates!`;
            case NOTIFICATION_EVENT_TYPE.APPLICANT_COMMENT:
                return `Message : ${notificationOutDto?.companyName} has a message to your ${notificationOutDto?.jobPostName} application: ${notificationOutDto?.comment}`;
            case NOTIFICATION_EVENT_TYPE.PLACEMENT_DRIVE_INVITATION:
            case NOTIFICATION_EVENT_TYPE.UNIVERSITY_BROADCAST:
                return `Message : ${notificationOutDto?.comment}`;
            default:
                return 'Message';
        }
    }

    static getTitle(notificationOutDto: NotificationOutDto): string {
        switch (notificationOutDto?.notificationEventType) {
            case NOTIFICATION_EVENT_TYPE.APPLICANT_STATUS_CHANGED:
                return `📣 Job Application Status`;
            case NOTIFICATION_EVENT_TYPE.APPLICANT_HIRING_ROUND_CHANGED:
                return `🚀 Job Application Updates`;
            case NOTIFICATION_EVENT_TYPE.APPLICANT_COMMENT:
                return `📧 You have a new message`;
            case NOTIFICATION_EVENT_TYPE.PLACEMENT_DRIVE_INVITATION:
            case NOTIFICATION_EVENT_TYPE.UNIVERSITY_BROADCAST:
                return `New Message from : ${notificationOutDto?.universityName}`;
            default:
                return 'New Message ';
        }
    }
}
