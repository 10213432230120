import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireList, AngularFireObject} from '@angular/fire/compat/database';
import {NOTIFICATION_STATUS, NotificationOutDto} from "./notification.service";
import {get} from "@angular/fire/database";

@Injectable({
    providedIn: 'root'
})
export class FirebaseApiService {
    private usersUrl = '/users';
    private configUrl = '/config';
    private webappVersionUrl = '/webapp_version';
    private notificationsUrl = '/notifications';
    private notificationListRef: AngularFireList<NotificationOutDto>;
    private DEFAULT_PAGE_SIZE = 50;

    constructor(private firebaseDatabase: AngularFireDatabase) {
    }

    async getNotificationCountOld(userId: string): Promise<number[]> {
        const notificationCountRef = this.firebaseDatabase.database.ref(
            `${this.usersUrl}/${userId}${this.notificationsUrl}`);
        try {
            const snapshotAllNotification = await notificationCountRef.orderByKey().once('value');
            const snapshotUnreadNotification = await notificationCountRef.orderByChild('status')
                .equalTo(NOTIFICATION_STATUS.UNREAD)
                .once('value');
            return [Object.keys(snapshotAllNotification.val()).length, Object.keys(snapshotUnreadNotification.val()).length];
        } catch (error) {
            console.error('Error fetching data:', error);
            return [0, 0];
        }
    }

    getNotificationCount(userId: string) {
        const notificationListRef: AngularFireList<NotificationOutDto> = this.firebaseDatabase.list(`${this.usersUrl}/${userId}${this.notificationsUrl}`);
        return notificationListRef.valueChanges();
    }

    getWebappVersion() {
        const webappVersionRef: AngularFireObject<string> = this.firebaseDatabase.object(`${this.configUrl}${this.webappVersionUrl}`);
        return webappVersionRef.valueChanges();
    }

    findAll(userId: string, lastTimeStamp?: string) {
        this.notificationListRef = this.firebaseDatabase.list(`${this.usersUrl}/${userId}${this.notificationsUrl}`,
            reference =>
                lastTimeStamp
                    ? reference.orderByKey().endBefore(lastTimeStamp).limitToLast(this.DEFAULT_PAGE_SIZE)
                    : reference.orderByKey().limitToLast(this.DEFAULT_PAGE_SIZE));
        return this.notificationListRef.valueChanges();
    }

    markOneAsRead(userId: string, notificationId: string): Promise<any> {
        return this.updateStatus(userId, notificationId);
    }

    updateStatus(userId: string, notificationId: string): Promise<void> {
        const notificationRef = this.firebaseDatabase.database.ref(`${this.usersUrl}/${userId}${this.notificationsUrl}/${notificationId}`);
        return notificationRef.update({status: NOTIFICATION_STATUS.READ});
    }

    markAllAsRead(userId: string): any {
        this.loadNotificationsData(userId).then(userNotifications => {
            const notificationIds: string[] = Object.keys(userNotifications);
            if (notificationIds?.length > 0) {
                notificationIds.forEach(notificationId => {
                    this.markOneAsRead(userId, notificationId).then();
                });
            }
        });
    }

    removeOne(notificationId: string): Promise<any> {
        return this.notificationListRef.remove(notificationId);
    }

    removeAll(userId: string): void {
        this.loadNotificationsData(userId).then(userNotifications => {
            const notificationIds: string[] = Object.keys(userNotifications);
            if (notificationIds?.length > 0) {
                notificationIds.forEach(notificationId => {
                    this.removeOne(notificationId).then();
                });
            }
        });
    }

    private async loadNotificationsData(userId: string): Promise<any> {
        try {
            return this.getNotificationsOnce(userId);
        } catch (error) {
            console.error('Error loading notifications:', error);
        }
    }

    private async getNotificationsOnce(userId: string): Promise<any> {
        const notificationsRef = this.firebaseDatabase
            .database
            .ref(`${this.usersUrl}/${userId}${this.notificationsUrl}`);
        try {
            const snapshot = await get(notificationsRef);
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return {};
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    }
}
