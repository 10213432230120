import {Injectable} from '@angular/core';
import {FirebaseApiService} from './firebase-api.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private lastVisitedId: string;

    constructor(private firebaseApiService: FirebaseApiService) {
    }

    getNotificationCountOld(userId: string): Promise<number[]> {
        return this.firebaseApiService.getNotificationCountOld(userId);
    }

    getNotificationCount(userId: string) {
        return this.firebaseApiService.getNotificationCount(userId);
    }

    getWebappVersion() {
        return this.firebaseApiService.getWebappVersion();
    }

    findAll(userId: string, lastTimeStamp?: string) {
        return this.firebaseApiService.findAll(userId, lastTimeStamp);
    }

    markAllAsRead(userId: string): Promise<any>[] {
        return this.firebaseApiService.markAllAsRead(userId);
    }

    markOneAsRead(notificationId: string, userId: string): Promise<any> {
        // todo: check can we make userId optoinal
        return this.firebaseApiService.markOneAsRead(userId, notificationId);
    }

    setLastVisitedId(lastVisitedId: string): void {
        this.lastVisitedId = lastVisitedId;
    }

    clearAll(userId: string): void {
        return this.firebaseApiService.removeAll(userId);
    }

    deleteLastVisited(): void {
        if (this.lastVisitedId) {
            this.firebaseApiService.removeOne(this.lastVisitedId).then(() => {
                this.lastVisitedId = null;
            });
        }
    }
}

export class NotificationOutDto {
    id?: string;
    userId?: string;
    candidateId?: string;
    applicantId?: string;
    jobPostId?: string;
    companyId?: string;
    universityId?: string;
    batchId?: string;
    placementDriveId?: string;
    title?: string;
    jobPostName?: string;
    applicantName?: string;
    applicantStatus?: string;
    currentApplicantStatus?: string;
    currentHiringRoundName?: string;
    companyName?: string;
    comment?: string;
    subject?: string;
    universityName?: string;
    batchName?: string;
    placementDriveName?: string;
    universityLogoUrl?: string;
    userProfileImageUrl?: string;
    companyLogoUrl?: string;
    metadata?: NotificationMetadata;
    status?: NOTIFICATION_STATUS;
    type?: NOTIFICATION_TYPE;
    notificationEventType: NOTIFICATION_EVENT_TYPE;
}

export class NotificationMetadata {
    status?: string;
    description?: string;
}

export class UserNotificationOutDto {
    userId?: string;
    fullName?: string;
    emailAddress?: string;
    contactNumber?: string;
    profileUrl?: string;
    role?: string;
    company?: string;
}

export enum NOTIFICATION_STATUS {
    READ = 'READ',
    UNREAD = 'UNREAD'
}

export enum NOTIFICATION_TYPE {
    RELEASE = 'RELEASE'
}

export enum NOTIFICATION_EVENT_TYPE {
    APPLICANT_HIRING_ROUND_CHANGED = 'APPLICANT_HIRING_ROUND_CHANGED',
    APPLICANT_STATUS_CHANGED = 'APPLICANT_STATUS_CHANGED',
    APPLICANT_COMMENT = 'APPLICANT_COMMENT',
    UNIVERSITY_BROADCAST = 'UNIVERSITY_BROADCAST',
    PLACEMENT_DRIVE_INVITATION = 'PLACEMENT_DRIVE_INVITATION'
}
