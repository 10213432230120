import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class GoogleMapLoaderService {
    isMapApiLoaded: boolean = false;

    getScriptWithAttributes(htmlScriptElement: HTMLScriptElement): HTMLScriptElement {
        htmlScriptElement.src = `${environment.GOOGLE_MAPS_CONFIG.mapsUrl}${environment.GOOGLE_MAPS_CONFIG.apiKey}`;
        htmlScriptElement.async = true;
        htmlScriptElement.defer = true;
        return htmlScriptElement;
    }

    handleOnload(htmlScriptElement: HTMLScriptElement): void {
        htmlScriptElement.onload = () => {
            this.setMapApiLoaded(true);
        };
    }

    handleOnError(htmlScriptElement: HTMLScriptElement): void {
        htmlScriptElement.onerror = () => {
            this.setMapApiLoaded(false);
        };
    }

    get getMapApiLoaded(): boolean {
        return this.isMapApiLoaded;
    }

    private setMapApiLoaded(isMapApiLoaded: boolean): void {
        this.isMapApiLoaded = isMapApiLoaded;
    }
}
