export class DateFormatter {
    public static MONTH_YEAR = 'MMM YYYY';
    public static MMM_DAY_YEAR = 'MMM d, y';
    public static FULL_DATE_AND_TIME = 'MMMM dd YYYY | hh:mm a';
    public static MONTH_APOSTROPHE_YEAR = "MMM'\''yy";
    public static DAY_MONTH_YEAR = 'dd.MM.YYYY';
    public static DAY_MONTH_YEAR_HH_mm = 'dd.MM.YYYY hh:mm a';
    public static YEAR_MONTH_DATE_HH_mm_ss = 'YYYY-MM-DD HH:mm:ss';
    public static HH_mm_A = 'hh:mm A';
    public static DAY_MONTH_YEAR_TIME = "dd MMMM yyyy h:mm a";
}

export class CalendarConstant {
    public static YEAR = 1950;
    public static MONTH = 0;
    public static DATE = 1;
}
